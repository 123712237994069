import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "firebase/app";
import "firebase/auth";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [state, changeState] = useState({
    userDataPresent: false,
    user: null,
  });

  const signInWithGoogle = () => {
    return new Promise((resolve) => {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          const provider = new firebase.auth.GoogleAuthProvider();
          firebase
            .auth()
            .signInWithPopup(provider)
            .then((response) => {
              changeState({ user: response.user, userDataPresent: true });
              resolve(response.user);
              return response.user;
            });
        });
    });
  };

  const signInWithFacebook = () => {
    return new Promise((resolve) => {
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          const provider = new firebase.auth.FacebookAuthProvider();
          firebase
            .auth()
            .signInWithPopup(provider)
            .then((response) => {
              changeState({ user: response.user, userDataPresent: true });
              resolve(response.user);
              return response.user;
            });
        });
    });
  };

  const signOut = () => {
    return new Promise((resolve) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          changeState({ user: null, userDataPresent: true });
          resolve();
        });
    });
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        changeState({ user: user, userDataPresent: true });
      } else {
        changeState({ user: null, userDataPresent: true });
      }
    });

    return () => unsubscribe();
  }, []);

  return {
    state,
    signInWithGoogle,
    signInWithFacebook,
    signOut,
  };
}
